import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ErrorNotFoundComponent } from "../components/error-component/error-component";
import { HomePage } from "./home-page/home-page";
import { PlayPage } from "./play-page/play-page";

export const Navigator = () => (
  <Router>
    <Routes>
      <Route exact path="/" element={<HomePage />} />
      <Route exact path="/product/play" element={<PlayPage />} />
      <Route element={<ErrorNotFoundComponent />} />
    </Routes>
  </Router>
);
