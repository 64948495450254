import React from "react";
import { connect } from "react-redux";

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div
          className={
            this.props.hover === "play"
              ? "mockup-component mockup-play"
              : "mockup-component mockup-stats"
          }
        >
          <img
            className={
              this.props.hover === "play"
                ? "mockup-phone-play"
                : "mockup-phone-stats"
            }
            src={process.env.PUBLIC_URL + "/img/background/iPhoneMockup.svg"}
          />
          <img
            className={
              this.props.hover === "play"
                ? "mockup-img-play"
                : "mockup-img-stats"
            }
            src={
              process.env.PUBLIC_URL + this.props.hover === "play"
                ? "/img/background/RPReplay.gif"
                : "/img/background/NeuinX Statics Demo.gif"
            }
          />
          <div
            className={
              this.props.hover === "play" ? "" : "mockup-bg bg-color-000000"
            }
          />
        </div>
      </React.Fragment>
    );
  }
}

const Redux = connect((store) => ({}))(Component);

export const MockupComponent = Redux;
