import React from "react";
import { connect } from "react-redux";
import "../component.css";

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div className="product-play-component bg-color-FFFFFF">
          <div className="product-play-content">
            <div className="product-play-title text-color-232C48">
              NeuinX Play
            </div>
            <div className="product-play-tags-container">
              <div className="neuinx-tag bg-color-2D6A62 text-color-F2F5E5">
                Fans
              </div>
              <div className="neuinx-tag bg-color-2D6A62 text-color-F2F5E5">
                Players
              </div>
              <div className="neuinx-tag bg-color-2D6A62 text-color-F2F5E5">
                Family
              </div>
            </div>
            <p className="product-play-text text-color-77808C">
              <em>
                Cherish the Moment &
                <span className="text-color-DE348C"> Share </span>
                the Journey
              </em>
            </p>
            <div className="product-play-btn-container">
              <a
                className="neuinx-btn bg-color-232C48"
                href="https://play.neuinx.com/"
                target="_blank"
              >
                Try It Now
              </a>
              <a className="neuinx-btn bg-color-8B95A3" href="/product/play">
                Learn More
              </a>
            </div>
          </div>
          <div className="product-play-phone">
            <img
              className="product-play-mockup"
              src={process.env.PUBLIC_URL + "/img/background/iPhoneMockup.svg"}
            />
            <div className="product-play-img-container bg-color-000000">
              <img
                src={process.env.PUBLIC_URL + "/img/background/RPReplay.gif"}
              />
            </div>
            <img
              className="product-play-status-bar"
              src={process.env.PUBLIC_URL + "/img/background/StatusBar.png"}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const Redux = connect((store) => ({}))(Component);

export const ProductPlayComponent = Redux;
