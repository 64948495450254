import React from "react";
import { connect } from "react-redux";
import { MockupComponent } from "./mockup-component";
import "../component.css";

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isHoverLeft: false, isHoverRight: false };
  }

  render() {
    return (
      <React.Fragment>
        <div className="products-300">
          <div className="products-300-content-play">
            <div className="products-300-content-title text-color-F2F5E5">
              NeuinX Play
            </div>
            <div className="products-300-tags-container">
              <div className="neuinx-tag bg-color-B2D9D8 text-color-2D6A62">
                Fans
              </div>
              <div className="neuinx-tag bg-color-B2D9D8 text-color-2D6A62">
                Players
              </div>
              <div className="neuinx-tag bg-color-B2D9D8 text-color-2D6A62">
                Family
              </div>
            </div>
            <a
              className="products-300-btn bg-color-F2F5E5 text-color-323C48"
              href="https://play.neuinx.com/"
              target="_blank"
            >
              Try It Now
            </a>
          </div>
          <div className="products-300-content-stats">
            <div className="products-300-content-title text-color-323C48 ">
              NeuinX Stats
            </div>
            <div className="products-300-tags-container">
              <div className="neuinx-tag bg-color-2D6A62 text-color-E0EFF0">
                Leagues
              </div>
              <div className="neuinx-tag bg-color-2D6A62 text-color-E0EFF0">
                Teams
              </div>
              <div className="neuinx-tag bg-color-2D6A62 text-color-E0EFF0">
                Coaches
              </div>
            </div>
            <a
              className="products-300-btn products-300-stats-btn bg-color-323C48 text-color-F2F5E5"
              // href="https://play.neuinx.com/"
            >
              Try It Now
            </a>
          </div>
        </div>
        <div
          className="products-1200"
          onMouseEnter={() => {
            this.setState({ isHoverRight: true });
          }}
          onMouseLeave={() => {
            this.setState({ isHoverRight: false });
          }}
        >
          <div
            className={
              this.state.isHoverLeft
                ? "products-1200-trapezoid hover-L"
                : this.state.isHoverRight
                ? "products-1200-trapezoid hover-R"
                : "products-1200-trapezoid"
            }
            onMouseEnter={() => {
              this.setState({ isHoverLeft: true });
            }}
            onMouseLeave={() => {
              this.setState({ isHoverLeft: false });
            }}
          >
            {this.state.isHoverLeft ? (
              <div className="products-1200-play-content-container">
                <MockupComponent hover={"play"} />
                <div
                  className={
                    this.state.isHoverLeft
                      ? "products-1200-play-content-hover"
                      : ""
                  }
                >
                  <div className="products-1200-title">NeuinX Play</div>
                  <div className="products-1200-tags-container">
                    <div className="products-1200-tag bg-color-B2D9D8">
                      <div className="text-color-2D6A62">Fans</div>
                    </div>
                    <div className="products-1200-tag bg-color-B2D9D8">
                      <div className="text-color-2D6A62">Players</div>
                    </div>
                    <div className="products-1200-tag bg-color-B2D9D8">
                      <div className="text-color-2D6A62">Family</div>
                    </div>
                  </div>
                </div>
              </div>
            ) : this.state.isHoverRight ? (
              <div className="products-try-stats">
                <div>TRY</div>
                <div>NOW</div>
              </div>
            ) : (
              <div className="products-1200-play">
                <div className="products-1200-title">NeuinX Play</div>
                <div className="products-1200-tags-container">
                  <div className="products-1200-tag bg-color-B2D9D8">
                    <div className="text-color-2D6A62">Fans</div>
                  </div>
                  <div className="products-1200-tag bg-color-B2D9D8">
                    <div className="text-color-2D6A62">Players</div>
                  </div>
                  <div className="products-1200-tag bg-color-B2D9D8">
                    <div className="text-color-2D6A62">Family</div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div
            className={
              this.state.isHoverRight
                ? "products-1200-stats-content-container-hover"
                : "products-1200-stats-content-container"
            }
          >
            {this.state.isHoverLeft ? (
              <div className="products-try-play">
                <div>TRY</div>
                <div>NOW</div>
              </div>
            ) : (
              <div className="products-1200-stats">
                <div
                  className={
                    this.state.isHoverRight
                      ? "products-1200-stats-content-hover"
                      : "products-1200-stats-content"
                  }
                >
                  <div className="products-1200-title">
                    <div className="text-color-232C48">NeuinX Stats</div>
                  </div>
                  <div
                    className={
                      this.state.isHoverRight
                        ? "products-1200-tags-container-hover"
                        : "products-1200-tags-container"
                    }
                  >
                    <div className="products-1200-tag bg-color-2D6A62">
                      <div className="text-color-E0EFF0">Leagues</div>
                    </div>
                    <div className="products-1200-tag bg-color-2D6A62">
                      <div className="text-color-E0EFF0">Teams</div>
                    </div>
                    <div className="products-1200-tag bg-color-2D6A62">
                      <div className="text-color-E0EFF0">Coaches</div>
                    </div>
                  </div>
                </div>
                {this.state.isHoverRight ? (
                  <MockupComponent hover={"stats"} />
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const Redux = connect((store) => ({}))(Component);

export const ProductsComponent = Redux;
