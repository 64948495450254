import React from "react";
import { connect } from "react-redux";
import { Drawer } from "antd";
import { HashLink } from "react-router-hash-link";
import "../component.css";

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDrawerOpened: false,
      isProductClick: false,
    };
  }

  render() {
    return (
      <React.Fragment>
        <div
          className="header-component bg-color-FFFFFF"
          onMouseLeave={() => {
            this.setState({ isProductClick: false });
          }}
        >
          <div className="header-logo-container">
            <a href="/">
              <img src={process.env.PUBLIC_URL + "/img/logo/neuinx_blue.png"} />
            </a>
          </div>
          <div className="header-option-container">
            <div className="header-option text-color-232C48">About us</div>
          </div>
          <div className="header-option-container">
            <div
              className="header-option text-color-232C48"
              onClick={() => {
                this.setState({ isProductClick: !this.state.isProductClick });
              }}
            >
              Products
              <div>
                <img
                  src={process.env.PUBLIC_URL + "/img/icon/arrow_left.png"}
                />
              </div>
            </div>
            <div
              className={
                this.state.isProductClick
                  ? "header-option-item-container"
                  : "header-option-item-container-hidden"
              }
              onMouseLeave={() => {
                this.setState({ isProductClick: false });
              }}
            >
              <a
                className={
                  this.state.isProductClick
                    ? "header-option-item text-color-232C48"
                    : "header-option-item-hidden text-color-232C48"
                }
                href="/product/play"
              >
                NeuinX Play
              </a>
            </div>
          </div>

          <div className="header-option-container">
            <HashLink
              className="header-option text-color-232C48"
              smooth
              to="/#news"
            >
              News
            </HashLink>
          </div>
          <div className="header-option-container">
            <div
              className="header-option text-color-232C48"
              onClick={() => {
                window.scrollTo({
                  left: 0,
                  top: 6500,
                  behavior: "smooth",
                });
              }}
            >
              Contact Us
            </div>
          </div>
          <div className="header-list">
            <img
              src={process.env.PUBLIC_URL + "/img/icon/list.png"}
              onClick={() => {
                this.setState({ isDrawerOpened: true });
              }}
            />
          </div>
          {(() => {
            if (this.state.isDrawerOpened) {
              return (
                <Drawer
                  width="300px"
                  placement="right"
                  closable={false}
                  onClose={() => {
                    this.setState({
                      isDrawerOpened: !this.state.isDrawerOpened,
                    });
                  }}
                  open={true}
                >
                  <div className="header-list-item-top text-color-232C48">
                    About Us
                  </div>
                  <div className="header-list-item">
                    <a className="text-color-232C48" href="/product/play">
                      Products
                    </a>
                  </div>
                  <div className="header-list-item">
                    <HashLink className="text-color-232C48" smooth to="/#news">
                      News
                    </HashLink>
                  </div>
                  <div
                    className="header-list-item-bottom text-color-232C48"
                    onClick={() => {
                      window.scrollTo({
                        left: 0,
                        top: 8500,
                        behavior: "smooth",
                      });
                    }}
                  >
                    Contact Us
                  </div>
                </Drawer>
              );
            }
          })()}
        </div>
      </React.Fragment>
    );
  }
}

const Redux = connect((store) => ({}))(Component);

export const HeaderComponent = Redux;
