import React from "react";
import { connect } from "react-redux";

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      analysisType: ["Field goal shots", "Rebound", "Assist", "Steal"],
      storage: ["3", "30", "60"],
      freemium: [
        { analysisCredit: "Login can get free 20 minutes / day" },
        {
          highlight: [
            "Output with waterprint",
            "Select up to 6 events for highlight output",
          ],
        },
      ],
      regular: [
        {
          analysisCredit: [
            "Extra 5 hours / month",
            "No upload video length restriction",
          ],
        },
        {
          highlight: [
            "Output without waterprint",
            "Unlimited highlight events output",
          ],
        },
      ],
      premium: [
        {
          analysisCredit: [
            "Extra 10 hours / month",
            "No upload video length restriction",
          ],
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <div
          className={
            this.props.className === "plan-component-mono"
              ? "plan-component-mono"
              : "plan-component"
          }
        >
          <div className="plan-title text-color-232C48">{this.props.title}</div>
          <div className="plan-describe text-color-232C48">
            {this.props.description}
          </div>
          {/* <div
            style={
              this.props.title === "Freemium"
                ? {}
                : {
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px 15px",
                    backgroundColor: "#AFEEEE60",
                    gap: "10px",
                  }
            }
          >
            <em
              className="plan-payment"
              style={{
                fontWeight: "600",
                color: "#DE348C",
              }}
            >
              {this.props.payment}
            </em>
            <div className="plan-billingMethod" style={{ color: "#B9B9B9" }}>
              {this.props.billingMethod}
            </div>
          </div> */}
          {this.props.title === "Freemium" ? (
            <a
              className="plan-btn"
              href="https://play.neuinx.com/"
              target="_blank"
            >
              Register now
            </a>
          ) : this.props.title === "Premium" ? (
            <div
              className="plan-btn"
              onClick={() => {
                window.scrollTo({
                  left: 0,
                  top: 8500,
                  behavior: "smooth",
                });
              }}
            >
              Contact us
            </div>
          ) : (
            <div className="plan-btn">Subscribe now</div>
          )}
          <div className="plan-detailInfo-container">
            <div className="plan-detailInfo-title text-color-77808C">
              Analysis Type
            </div>
            {this.props.title === "Premium" ? (
              this.state.analysisType.map((analysisType, index) => {
                return (
                  <div
                    key={index}
                    className="plan-detailInfo-describe text-color-77808C"
                  >
                    <div className="plan-detailInfo-dot" />
                    <div className="plan-detailInfo">{analysisType}</div>
                  </div>
                );
              })
            ) : (
              <div className="plan-detailInfo-describe text-color-77808C">
                <div className="plan-detailInfo-dot" />
                <div className="plan-detailInfo">
                  {this.state.analysisType[0]}
                </div>
              </div>
            )}
          </div>
          <div className="plan-detailInfo-container">
            <div className="plan-detailInfo-title text-color-77808C">
              Analysis Credit
            </div>
            {this.props.title === "Freemium" ? (
              <div className="plan-detailInfo-describe text-color-77808C">
                <div className="plan-detailInfo-dot" />
                <div className="plan-detailInfo">
                  {this.state.freemium[0].analysisCredit}
                </div>
              </div>
            ) : this.props.title === "Premium" ? (
              this.state.premium[0].analysisCredit.map(
                (analysisCredit, index) => {
                  return (
                    <div
                      key={index}
                      className="plan-detailInfo-describe text-color-77808C"
                    >
                      <div className="plan-detailInfo-dot" />
                      <div className="plan-detailInfo">{analysisCredit}</div>
                    </div>
                  );
                }
              )
            ) : (
              this.state.regular[0].analysisCredit.map(
                (analysisCredit, index) => {
                  return (
                    <div
                      key={index}
                      className="plan-detailInfo-describe text-color-77808C"
                    >
                      <div className="plan-detailInfo-dot" />
                      <div className="plan-detailInfo">{analysisCredit}</div>
                    </div>
                  );
                }
              )
            )}
          </div>
          <div className="plan-detailInfo-container">
            <div className="plan-detailInfo-title text-color-77808C">
              Highlight
            </div>
            {this.props.title === "Freemium"
              ? this.state.freemium[1].highlight.map((highlight, index) => {
                  return (
                    <div
                      key={index}
                      className="plan-detailInfo-describe text-color-77808C"
                    >
                      <div className="plan-detailInfo-dot" />
                      <div className="plan-detailInfo">{highlight}</div>
                    </div>
                  );
                })
              : this.state.regular[1].highlight.map((highlight, index) => {
                  return (
                    <div
                      key={index}
                      className="plan-detailInfo-describe text-color-77808C"
                    >
                      <div className="plan-detailInfo-dot" />
                      <div className="plan-detailInfo">{highlight}</div>
                    </div>
                  );
                })}
          </div>
          <div className="plan-detailInfo-container">
            <div className="plan-detailInfo-title text-color-77808C">
              Storage
            </div>
            <div className="plan-detailInfo-describe text-color-77808C">
              <div className="plan-detailInfo-dot" />
              <div className="plan-detailInfo">
                Each video will store{" "}
                {this.props.title === "Freemium"
                  ? this.state.storage[0]
                  : this.props.title === "Premium"
                  ? this.state.storage[2]
                  : this.state.storage[1]}{" "}
                days
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const Redux = connect((store) => ({}))(Component);

export const PlanComponent = Redux;
