import React from "react";
import { connect } from "react-redux";
import "../component.css";

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div className="our-mission-component bg-color-F7F7F7">
          <div className="our-mission-trapezoid">
            <div className="our-mission-topic text-color-77808C">
              <p className="our-mission-title text-color-232C48">Our Mission</p>
              <p className="our-mission-content">
                <span>
                  “
                  <span className="text-color-DE348C">
                    Driven by our passion for the game
                  </span>
                  , our mission is to
                  <span className="text-color-DE348C"> empower </span>
                  fellow
                  <span className="text-color-6DC3C0"> sports lovers </span>
                  with
                  <span className="text-color-DE348C">
                    {" "}
                    AI solutions based{" "}
                  </span>
                  on the wants and needs directly from leagues, players, and
                  coaches.”
                </span>
              </p>
            </div>
          </div>
          <div className="our-mission-video-container">
            <img
              src={process.env.PUBLIC_URL + "/img/background/Shot Analysis.gif"}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const Redux = connect((store) => ({}))(Component);

export const OurMissionComponent = Redux;
