import React from "react";
import { connect } from "react-redux";
import "../component.css";

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div className="our-ai-support-component bg-color-FFFFFF">
          <div className="our-ai-support-title text-color-232C48">
            Our AI Support
          </div>
          <div className="our-ai-support-content-container">
            <div className="our-ai-support-content text-color-77808C">
              <div className="our-ai-support-topic text-color-232C48">
                Autobox Score
              </div>
              <em>
                <p className="our-ai-support-describe-title">
                  <span className="text-color-DE348C">Eliminate </span>
                  <span className="text-color-232C48">the </span>
                  <span className="text-color-6DC3C0">
                    time-consuming process of traditional video editing.
                  </span>
                </p>
              </em>
              <p className="our-ai-support-describe">
                <span>
                  Instantly transforming performance data into cherished
                  memories and insights, saving players and parents invaluable
                  time.
                </span>
              </p>
            </div>
            <div className="our-ai-support-illustrate-1">
              <img
                src={
                  process.env.PUBLIC_URL + "/img/background/Autobox Score.gif"
                }
              />
            </div>
          </div>
          <div className="our-ai-support-content-container">
            <div className="our-ai-support-illustrate-2">
              <img src={process.env.PUBLIC_URL + "/img/background/T1 xr.gif"} />
            </div>
            <div className="our-ai-support-content text-color-77808C">
              <div className="our-ai-support-topic text-color-232C48">
                XR Highlight Generation
              </div>
              <em>
                <p className="our-ai-support-describe-title">
                  <span className="text-color-DE348C">
                    Seamlessly integrating 3D effects{" "}
                  </span>
                  <span className="text-color-6DC3C0">
                    tailored to your body mesh.
                  </span>
                </p>
              </em>
              <p className="our-ai-support-describe">
                <span>
                  Our technology elevates your moments into an immersive,
                  dynamic showcase. Unlike traditional highlight creation, which
                  often requires manual editing and lacks personalization.
                </span>
              </p>
            </div>
            <div className="our-ai-support-illustrate-3">
              <img src={process.env.PUBLIC_URL + "/img/background/T1 xr.gif"} />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const Redux = connect((store) => ({}))(Component);

export const OurAiSupportComponent = Redux;
