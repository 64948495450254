import React from "react";
import { connect } from "react-redux";
import { HeaderComponent } from "../../components/header-component/header-component";
import { PlayCoverComponent } from "../../components/cover-component/play-cover-component";
import { PlayOnTheGoComponent } from "../../components/play-on-the-go-component/play-on-the-go-component";
import { ChooseTheBestPlanComponent } from "../../components/choose-the-best-plan-component/choose-the-best-plan-component";
import { OurAiSupportComponent } from "../../components/our-ai-support-component/our-ai-support-component";
import { TutorialComponent } from "../../components/tutorial-component/tutorial-component";
import { ProductsComponent } from "../../components/products-component/products-component";
import { ContactUsComponent } from "../../components/contact-us-component/contact-us-component";

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <HeaderComponent />
        <div style={{ height: "100vh", paddingTop: "65px" }}>
          <PlayCoverComponent />
          <PlayOnTheGoComponent />
          <ChooseTheBestPlanComponent />
          <OurAiSupportComponent />
          <TutorialComponent />
          <ProductsComponent />
          <ContactUsComponent />
        </div>
      </React.Fragment>
    );
  }
}

const Redux = connect((store) => ({}))(Component);

export const PlayPage = Redux;
