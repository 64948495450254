import React from "react";
import { connect } from "react-redux";
import { PlanComponent } from "./plan-component";
import "../component.css";

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      plan: [
        {
          title: "Freemium",
          description: "",
          payment: "",
          billingMethod: "",
        },
        {
          title: "Regular Plan",
          description: "",
          payment: "NT$ 600 / month",
          billingMethod: "",
        },
        {
          title: "Premium",
          description: "",
          payment: "NT$ 1300 / month",
          billingMethod: "",
        },
      ],
      planIndex: 0,
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className="choose-the-best-plan-component bg-color-F7F7F7">
          <div className="choose-the-best-plan-title text-color-232C48">
            Choose the best plan for you
          </div>
          <div className="choose-the-best-plan-mono">
            <div
              className="neuinx-btn-round choose-the-best-plan-left-btn"
              onClick={() => {
                if (this.state.planIndex > 0) {
                  this.setState({ planIndex: this.state.planIndex - 1 });
                } else {
                  this.setState({ planIndex: 2 });
                }
              }}
            >
              <img src={process.env.PUBLIC_URL + "/img/icon/arrow_down.svg"} />
            </div>
            <PlanComponent
              className={"plan-component-mono"}
              title={this.state.plan[this.state.planIndex].title}
              description={this.state.plan[this.state.planIndex].description}
              payment={this.state.plan[this.state.planIndex].payment}
              billingMethod={
                this.state.plan[this.state.planIndex].billingMethod
              }
              detailInfo={this.state.plan[this.state.planIndex].detailInfo}
            />
            <div
              className="neuinx-btn-round choose-the-best-plan-right-btn"
              onClick={() => {
                if (this.state.planIndex < 2) {
                  this.setState({ planIndex: this.state.planIndex + 1 });
                } else {
                  this.setState({ planIndex: 0 });
                }
              }}
            >
              <img src={process.env.PUBLIC_URL + "/img/icon/arrow_down.svg"} />
            </div>
          </div>
          <div className="choose-the-best-plan-map">
            {this.state.plan.map((plan, index) => {
              return (
                <PlanComponent
                  key={index}
                  title={plan.title}
                  description={plan.description}
                  payment={plan.payment}
                  billingMethod={plan.billingMethod}
                />
              );
            })}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const Redux = connect((store) => ({}))(Component);

export const ChooseTheBestPlanComponent = Redux;
