import React from "react";
import { connect } from "react-redux";
import { HomeCoverComponent } from "../../components/cover-component/home-cover-component";
import { HeaderComponent } from "../../components/header-component/header-component";
import { OurMissionComponent } from "../../components/our-mission-component/our-mission-component";
import { ProductPlayComponent } from "../../components/product-play-component/product-play-component";
import { ProductStatsComponent } from "../../components/product-stats-component/product-stats-component";
import { OtherWorksComponent } from "../../components/other-works-component/other-works-component";
import { PromotionVideoComponent } from "../../components/promotion-video-component/promotion-video-component";
import { NewsComponent } from "../../components/news-component/news-component";
import { ProductsComponent } from "../../components/products-component/products-component";
import { ContactUsComponent } from "../../components/contact-us-component/contact-us-component";

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <HeaderComponent />
        <div style={{ height: "100vh", paddingTop: "65px" }}>
          <HomeCoverComponent />
          <OurMissionComponent />
          <ProductPlayComponent />
          <ProductStatsComponent />
          <OtherWorksComponent />
          <PromotionVideoComponent />
          <NewsComponent />
          <ProductsComponent />
          <ContactUsComponent />
        </div>
      </React.Fragment>
    );
  }
}

const Redux = connect((store) => ({}))(Component);

export const HomePage = Redux;
