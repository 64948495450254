import React from "react";
import { connect } from "react-redux";

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div className="story-component">
          <a
            className="story-article-link"
            href={this.props.url}
            target="_blank"
          >
            <div className="story-img-container">
              <img
                className="story-img"
                src={process.env.PUBLIC_URL + this.props.img}
              />
            </div>
            <div className="story-article bg-color-FFFFFF">
              <div className="story-article-info text-color-A0ACBD">
                <div className="story-article-by">{this.props.by}</div>
                <div>{this.props.date}</div>
              </div>
              <p className="story-title text-color-3C4856">
                {this.props.title}
              </p>
              <p className="story-content text-color-A0ACBD">
                {this.props.content}
              </p>
            </div>
          </a>
          <a className="story-btn" href={this.props.url} target="_blank">
            Full Story
          </a>
        </div>
      </React.Fragment>
    );
  }
}

const Redux = connect((store) => ({}))(Component);

export const StoryComponent = Redux;
