import React from "react";
import { Provider } from "react-redux";
import { Store } from "./redux/store";
import { Navigator } from "./pages/navigator";
import "./App.css";
import "./fonts/PingFangRegular.ttf";
import "./fonts/PingFangBold.ttf";
import "./fonts/PingFangLight.ttf";

export const App = () => (
  <Provider store={Store}>
    <Navigator />
  </Provider>
);
