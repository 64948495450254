import React from "react";
import { connect } from "react-redux";
import "../component.css";

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      illustrate: [
        { src: "/img/background/Games pickup.png", describe: "Games / Pickup" },
        {
          src: "/img/background/Personal training.png",
          describe: "Personal Training",
        },
      ],
      btnIndex: 0,
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className="play-on-the-go-component bg-color-FFFFFF">
          <p className="play-on-the-go-title text-color-232C48">
            Play on the <span className="text-color-DE348C">Go</span>!
          </p>
          <p className="play-on-the-go-text text-color-77808C">
            <span className="play-on-the-go-topic">
              <em>
                <span className="text-color-DE348C">No Hassle</span>
                <span>, </span>
                <span className="play-on-the-go-border">Just Your Phone</span>,
                Any Angle, Any Moment, Everywhere.
              </em>
            </span>
            <span className="play-on-the-go-content">
              <em>
                “
                <span className="text-color-6DC3C0">
                  No cameras in the court?{" "}
                </span>
                No problem. Use our app to stay connected and follow the
                proceeding in real time, wherever you are.”
              </em>
            </span>
          </p>
          <div className="play-on-the-go-container-mono">
            <div className="play-on-the-go-illustrate-mono-container">
              <img
                className="play-on-the-go-illustrate-mono"
                src={
                  process.env.PUBLIC_URL +
                  this.state.illustrate[this.state.btnIndex].src
                }
              />
              <div className="play-on-the-go-describe">
                <em>{this.state.illustrate[this.state.btnIndex].describe}</em>
              </div>
            </div>
            <div
              className="neuinx-btn-round play-on-the-go-select-btn"
              onClick={() => {
                if (this.state.btnIndex < 1) {
                  this.setState({ btnIndex: this.state.btnIndex + 1 });
                } else {
                  this.setState({ btnIndex: 0 });
                }
              }}
            >
              <img src={process.env.PUBLIC_URL + "/img/icon/arrow_down.svg"} />
            </div>
          </div>
          <div className="play-on-the-go-illustrate">
            <div className="play-on-the-go-illustrate-container">
              <img
                src={
                  process.env.PUBLIC_URL + "/img/background/Games pickup.png"
                }
              />
              <div className="play-on-the-go-illustrate-font text-color-77808C">
                <em>Games / Pickup</em>
              </div>
            </div>
            <div className="play-on-the-go-illustrate-container">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/img/background/Personal training.png"
                }
              />
              <div className="play-on-the-go-illustrate-font text-color-77808C">
                <em>Personal Training</em>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const Redux = connect((store) => ({}))(Component);

export const PlayOnTheGoComponent = Redux;
