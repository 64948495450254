import React from "react";
import { connect } from "react-redux";
import "../component.css";

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div className="product-stats-component bg-color-F7F7F7">
          <div className="product-stats-content">
            <div className="product-stats-title text-color-232C48">
              NeuinX Stats
            </div>
            <div className="product-stats-tags-container">
              <div className="neuinx-tag bg-color-2D6A62 text-color-F2F5E5">
                Leagues
              </div>
              <div className="neuinx-tag bg-color-2D6A62 text-color-F2F5E5">
                Teams
              </div>
              <div className="neuinx-tag bg-color-2D6A62 text-color-F2F5E5">
                Coaches
              </div>
            </div>
            <div className="product-stats-phone">
              <img
                className="product-stats-mockup"
                src={
                  process.env.PUBLIC_URL + "/img/background/iPhoneMockup.svg"
                }
              />
              <div className="product-stats-demo bg-color-000000">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/img/background/NeuinX Statics Demo.gif"
                  }
                />
              </div>
              <img
                className="product-stats-status-bar"
                src={process.env.PUBLIC_URL + "/img/background/StatusBar.png"}
              />
            </div>
          </div>
          <div className="product-stats-text text-color-77808C">
            <em>
              <span className="text-color-DE348C">Connect</span>
              <span className="product-stats-text-padding">,</span>
              <span className="text-color-DE348C">Capture</span>
              <span className="product-stats-text-padding">,</span>
              <span className="text-color-DE348C">Compete</span>
              <span>:</span>
            </em>
            <em>Your All-in-one Platform for</em>
            <em>Seamless Registration, Box</em>
            <em>score Insight, and Video</em>
            <em>Sharing</em>
            <div
              className="neuinx-btn bg-color-232C48"
              onClick={() => {
                window.scrollTo({
                  left: 0,
                  top: 6500,
                  behavior: "smooth",
                });
              }}
            >
              Contact us for demo
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const Redux = connect((store) => ({}))(Component);

export const ProductStatsComponent = Redux;
