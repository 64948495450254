import React from "react";
import { connect } from "react-redux";
import "../component.css";

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div className="home-cover-topic">
          <img
            className="home-cover-banner"
            src="./img/background/Banner.png"
          />
          <div className="home-cover-banner-cover"></div>
          <div className="home-cover-logo-container">
            <img src={process.env.PUBLIC_URL + "/img/logo/neuinx_white.png"} />
          </div>
          <p className="home-cover-text">
            <span className="text-color-6DC3C0">Focus</span>
          </p>
          <p className="home-cover-text text-color-FFFFFF">
            for <span className="text-color-DE348C">Sports lovers</span>
          </p>
          <p className="home-cover-describe text-color-FFFFFF">
            Our solution puts
            <span className="text-color-6DC3C0">
              {" "}
              pro-level sports insights{" "}
            </span>
            at your
            <span className="text-color-DE348C"> fingertips</span>.
          </p>
          <div className="home-cover-arrow-container">
            <img
              className="home-cover-arrow-down"
              src={process.env.PUBLIC_URL + "/img/icon/arrow_down.svg"}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const Redux = connect((store) => ({}))(Component);

export const HomeCoverComponent = Redux;
