import React from "react";
import { connect } from "react-redux";
import "../component.css";

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      illustrate: [
        {
          src: "/img/background/Basketball Scoring.gif",
          describe: "Auto Box Score",
        },
        { src: "/img/background/VR basketball.gif", describe: "VR Basketball" },
        {
          src: "/img/background/Shoot Detection.gif",
          describe: "Shooting Detection",
        },
      ],
      illustrateIndex: 0,
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className="other-works-component bg-color-FFFFFF">
          <div className="other-works-title text-color-232C48">Other Works</div>
          <em className="other-works-content text-color-77808C">
            Solutions build on requirements from Leagues, Coaches, and Players
          </em>
          <div className="other-works-img-container">
            <div
              className="neuinx-btn-round other-works-btn-left"
              onClick={() => {
                if (this.state.illustrateIndex > 0) {
                  this.setState({
                    illustrateIndex: this.state.illustrateIndex - 1,
                  });
                } else {
                  this.setState({
                    illustrateIndex: 2,
                  });
                }
              }}
            >
              <img src={process.env.PUBLIC_URL + "/img/icon/arrow_down.svg"} />
            </div>
            <div className="other-works-illustrate-mono">
              <img
                src={
                  process.env.PUBLIC_URL +
                  this.state.illustrate[this.state.illustrateIndex].src
                }
              />
              <div className="other-works-describe text-color-77808C">
                {this.state.illustrate[this.state.illustrateIndex].describe}
              </div>
              <div className="other-works-cover bg-color-000000" />
            </div>
            <div className="other-works-illustrate-container">
              <img
                src={process.env.PUBLIC_URL + this.state.illustrate[0].src}
              />
              <div className="other-works-describe">
                {this.state.illustrate[0].describe}
              </div>
              <div className="other-works-cover bg-color-000000" />
            </div>
            {/* <div
              style={{
                width: "22%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                position: "relative",
                backgroundColor: "#000000",
              }}
            >
              <div
                style={{
                  paddingBottom: "6px",
                  position: "absolute",
                  bottom: 0,
                  fontSize: "20px",
                  color: "#FFFFFF",
                  zIndex: 2,
                }}
              >
                3D Ball Trajectory
              </div>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#000000",
                  opacity: 0.4,
                  zIndex: 1,
                }}
              />
            </div> */}
            <div className="other-works-illustrate-container">
              <img
                src={process.env.PUBLIC_URL + this.state.illustrate[1].src}
              />
              <div className="other-works-describe">
                {this.state.illustrate[1].describe}
              </div>
              <div className="other-works-cover bg-color-000000" />
            </div>
            <div className="other-works-illustrate-container">
              <img
                src={process.env.PUBLIC_URL + this.state.illustrate[2].src}
              />
              <div className="other-works-describe">
                {this.state.illustrate[2].describe}
              </div>
              <div className="other-works-cover bg-color-000000" />
            </div>
            <div
              className="neuinx-btn-round other-works-btn-right"
              onClick={() => {
                if (this.state.illustrateIndex < 2) {
                  this.setState({
                    illustrateIndex: this.state.illustrateIndex + 1,
                  });
                } else {
                  this.setState({
                    illustrateIndex: 0,
                  });
                }
              }}
            >
              <img src={process.env.PUBLIC_URL + "/img/icon/arrow_down.svg"} />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const Redux = connect((store) => ({}))(Component);

export const OtherWorksComponent = Redux;
