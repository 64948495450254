import React from "react";
import { connect } from "react-redux";
import "../component.css";

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div className="contact-us-component bg-color-FFFFFF">
          <div className="contact-us-info text-color-4D6974">
            <img
              className="contact-us-logo"
              src={
                process.env.PUBLIC_URL + "/img/logo/neuinxLogoBlueSquare1.png"
              }
            />
            <div className="contact-us-img-email-container">
              <img
                className="contact-us-img-email"
                src={process.env.PUBLIC_URL + "/img/icon/email.png"}
              />
              john.tsai@neuinx.com
            </div>
            <div className="contact-us-img-phone-container">
              <img
                className="contact-us-img-phone"
                src={process.env.PUBLIC_URL + "/img/icon/phone.png"}
              />
              (+886) 0937875250
            </div>
            <div className="contact-us-img-office-container">
              <img
                className="contact-us-img-office"
                src={process.env.PUBLIC_URL + "/img/icon/office.png"}
              />
              1 F., No. 123, Suiyuan 1st St., Sanmin Dist.
            </div>
            <div className="contact-us-img-location-container">
              <img
                className="contact-us-img-location"
                src={process.env.PUBLIC_URL + "/img/icon/location.png"}
              />
              Kaohsiung City 807019, Taiwan (R.O.C.)
            </div>
            <div className="contact-us-link-container">
              <div className="contact-us-text">
                <p className="contact-us-text-title text-color-47515F">
                  <span className="contact-us-font-700">
                    <span className="contact-us-border-bottom">Co</span>
                    nnect{" "}
                  </span>
                  with us
                </p>
                <div className="contact-us-icons-container">
                  <a
                    href="https://tw.linkedin.com/company/neuinx"
                    target="_blank"
                  >
                    <img
                      className="contact-us-icon"
                      src={process.env.PUBLIC_URL + "/img/icon/LinkedIn.png"}
                    />
                  </a>
                  <a
                    href="https://www.facebook.com/profile.php?id=61551086316091"
                    target="_blank"
                  >
                    <img
                      className="contact-us-icon"
                      src={process.env.PUBLIC_URL + "/img/icon/Facebook.png"}
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/neuinx_tech/"
                    target="_blank"
                  >
                    <img
                      className="contact-us-icon"
                      src={process.env.PUBLIC_URL + "/img/icon/Instagram.png"}
                    />
                  </a>
                </div>
              </div>
              <img
                className="contact-us-awards"
                src={process.env.PUBLIC_URL + "/img/icon/awards.png"}
              />
            </div>
          </div>
          {/* <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <div
              style={{
                paddingBottom: "3px",
                display: "flex",
                flexDirection: "row",
                fontFamily: "PingMedium",
                fontSize: "20px",
                color: "#232C48",
              }}
            >
              <div
                style={{
                  borderBottom: "2px solid #20857C95",
                }}
              >
                Se
              </div>
              nd us a
              <div style={{ paddingLeft: "6px", fontWeight: "600" }}>
                Message
              </div>
            </div>
            <div
              style={{
                paddingLeft: "4px",
                display: "flex",
                flexDirection: "column",
                fontFamily: "PingMedium",
                fontSize: "14px",
                gap: "3px",
              }}
            >
              <div>Name</div>
              <div
                style={{
                  width: "100%",
                  height: "33px",
                  backgroundColor: "#E5E5CC80",
                  border: "2px solid #CCCCCC",
                  borderRadius: "5px",
                }}
              ></div>
            </div>
            <div
              style={{
                paddingLeft: "4px",
                display: "flex",
                flexDirection: "column",
                fontFamily: "PingMedium",
                fontSize: "14px",
                gap: "3px",
              }}
            >
              <div>Email</div>
              <div
                style={{
                  width: "100%",
                  height: "33px",
                  backgroundColor: "#E5E5CC80",
                  border: "2px solid #CCCCCC",
                  borderRadius: "5px",
                }}
              ></div>
            </div>
            <div
              style={{
                paddingLeft: "4px",
                paddingBottom: "10px",
                display: "flex",
                flexDirection: "column",
                fontFamily: "PingMedium",
                fontSize: "14px",
                gap: "3px",
              }}
            >
              <div>Message</div>
              <div
                style={{
                  width: "100%",
                  height: "63px",
                  backgroundColor: "#E5E5CC80",
                  border: "2px solid #CCCCCC",
                  borderRadius: "5px",
                }}
              ></div>
            </div>
            <div
              className="contact-us-btn"
              style={{
                padding: "10px 8px",
                borderRadius: "8px",
                backgroundColor: "#232C48",
                boxShadow: "0px 0px 8px 0px #999999",
                fontFamily: "Ping",
                textAlign: "center",
                color: "#FFFFFF",
                cursor: "pointer",
              }}
            >
              Send Message
            </div>
          </div> */}
        </div>
      </React.Fragment>
    );
  }
}

const Redux = connect((store) => ({}))(Component);

export const ContactUsComponent = Redux;
