import React from "react";
import { connect } from "react-redux";
import { StoryComponent } from "./story-component";
import "../component.css";

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      story: [
        {
          index: 0,
          url: "https://www.digitimes.com/news/a20231006PR203.html?chid=9",
          img: "/img/background/DigitimesAsia 1013.png",
          by: "by Digitimes Asia",
          date: "2023-10-13",
          title:
            "NeuinX seizes sports image analysis opportunities and secures CES 202...",
          content:
            "With the aim of attaining outstanding results in competitions, a global trend of combining sport a...",
        },
        {
          index: 1,
          url: "https://m.facebook.com/story.php?story_fbid=pfbid02ovYMhY4cXar8vbPFPkZ9KDdaPsnhaincHPmKQCvQFigEN6tRt153VHCh62cm9MvAl&id=100068111227167",
          img: "/img/background/2023_GCamp_1.jpg",
          by: "by G Camp",
          date: "2023-08-08",
          title: "2023 KPMG台灣創新科技大賽",
          content:
            "聯手合作夥伴KPMG安侯建業為新創所打造的「KPMG台灣創新科技大賽」…",
        },
        {
          index: 2,
          url: "https://meet.bnext.com.tw/articles/view/50241?utm_source=copyshare",
          img: "/img/background/2023MeetStartup.png",
          by: "by Meet創業小聚",
          date: "2023-04-19",
          title: "CES獲獎團隊NeuinX用AI打造精準籃球計分系統",
          content:
            "街頭打球也能有完整的數據紀錄, CES獲獎團隊NeuinX用AI打造精準籃球計分系統",
        },
        {
          index: 3,
          url: "https://news.ustv.com.tw/newsdetail/20221228A052",
          img: "/img/background/news-CES-1.jpg",
          by: "by 非凡新聞",
          date: "2022-12-28",
          title: "征戰CES!科技部率96家新創赴美 聚焦醫療.AI",
          content:
            "有年度科技風向球的CES美國消費性電子展，即將在2023年1月於美國拉斯維加斯登場！",
        },
        {
          index: 4,
          url: "https://www.cna.com.tw/news/firstnews/202107310053.aspx?fbclid=IwAR0kWSif0K0xhG6_tpk8xARvUQeFW559It0RKByqtBRYb3t5tpgx_plpNu0",
          img: "/img/background/news-tabletennis-1.jpg",
          by: "by 中央通訊社",
          date: "2021-07-31",
          title: "台灣桌球選手東奧表現亮眼 運動科學團隊助攻",
          content:
            "台灣桌球代表隊在2020東京奧運拿下混雙銅牌，男單也追平隊史最佳紀錄。選手良好表現，背後有運動科學團隊相助，在體能訓練、技戰術演練、情蒐等發揮功效。台灣師範大學今天發出新聞稿指出，今年東奧桌球代表隊，可說是歷年來接受科學化訓練程度最高的一次。由台師大講座教授洪聰敏擔任運科召集人，整合國訓中心運科處，並結合清華大學學者朱宏國、胡敏君、邱文信、吳德成等人組成堅強團隊，都是國內一時之選。....",
        },
      ],
      newsIndex: 0,
      newsMapIndex: 0,
    };
  }

  render() {
    return (
      <React.Fragment>
        <div id="news" className="news-component bg-color-FFFFFF">
          <div className="news-title text-color-232C48">News</div>
          <div className="news-container">
            <div
              className={
                this.state.newsIndex === 0
                  ? "neuinx-btn-round news-left-btn-mono hidden"
                  : "neuinx-btn-round news-left-btn-mono"
              }
              onClick={() => {
                if (this.state.newsIndex > 0) {
                  this.setState({ newsIndex: this.state.newsIndex - 1 });
                } else {
                  this.setState({ newsIndex: 0 });
                }
              }}
            >
              <img src={process.env.PUBLIC_URL + "/img/icon/arrow_down.svg"} />
            </div>
            <div
              className={
                this.state.newsMapIndex === 0
                  ? "neuinx-btn-round news-left-btn-map hidden"
                  : "neuinx-btn-round news-left-btn-map"
              }
              onClick={() => {
                if (this.state.newsMapIndex > 0) {
                  this.setState({ newsMapIndex: this.state.newsMapIndex - 1 });
                } else {
                  this.setState({ newsMapIndex: 0 });
                }
              }}
            >
              <img src={process.env.PUBLIC_URL + "/img/icon/arrow_down.svg"} />
            </div>
            <div className="news-map">
              <StoryComponent
                url={this.state.story[this.state.newsMapIndex].url}
                img={this.state.story[this.state.newsMapIndex].img}
                by={this.state.story[this.state.newsMapIndex].by}
                date={this.state.story[this.state.newsMapIndex].date}
                title={this.state.story[this.state.newsMapIndex].title}
                content={this.state.story[this.state.newsMapIndex].content}
              />
              {this.state.newsMapIndex < this.state.story.length - 1 ? (
                <StoryComponent
                  url={this.state.story[this.state.newsMapIndex + 1].url}
                  img={this.state.story[this.state.newsMapIndex + 1].img}
                  by={this.state.story[this.state.newsMapIndex + 1].by}
                  date={this.state.story[this.state.newsMapIndex + 1].date}
                  title={this.state.story[this.state.newsMapIndex + 1].title}
                  content={
                    this.state.story[this.state.newsMapIndex + 1].content
                  }
                />
              ) : (
                <StoryComponent
                  url={
                    this.state.story[
                      this.state.newsMapIndex - this.state.story.length + 1
                    ].url
                  }
                  img={
                    this.state.story[
                      this.state.newsMapIndex - this.state.story.length + 1
                    ].img
                  }
                  by={
                    this.state.story[
                      this.state.newsMapIndex - this.state.story.length + 1
                    ].by
                  }
                  date={
                    this.state.story[
                      this.state.newsMapIndex - this.state.story.length + 1
                    ].date
                  }
                  title={
                    this.state.story[
                      this.state.newsMapIndex - this.state.story.length + 1
                    ].title
                  }
                  content={
                    this.state.story[
                      this.state.newsMapIndex - this.state.story.length + 1
                    ].content
                  }
                />
              )}
              {this.state.newsMapIndex < this.state.story.length - 2 ? (
                <StoryComponent
                  url={this.state.story[this.state.newsMapIndex + 2].url}
                  img={this.state.story[this.state.newsMapIndex + 2].img}
                  by={this.state.story[this.state.newsMapIndex + 2].by}
                  date={this.state.story[this.state.newsMapIndex + 2].date}
                  title={this.state.story[this.state.newsMapIndex + 2].title}
                  content={
                    this.state.story[this.state.newsMapIndex + 2].content
                  }
                />
              ) : (
                <StoryComponent
                  url={
                    this.state.story[
                      this.state.newsMapIndex - this.state.story.length + 2
                    ].url
                  }
                  img={
                    this.state.story[
                      this.state.newsMapIndex - this.state.story.length + 2
                    ].img
                  }
                  by={
                    this.state.story[
                      this.state.newsMapIndex - this.state.story.length + 2
                    ].by
                  }
                  date={
                    this.state.story[
                      this.state.newsMapIndex - this.state.story.length + 2
                    ].date
                  }
                  title={
                    this.state.story[
                      this.state.newsMapIndex - this.state.story.length + 2
                    ].title
                  }
                  content={
                    this.state.story[
                      this.state.newsMapIndex - this.state.story.length + 2
                    ].content
                  }
                />
              )}
            </div>
            <div className="news-mono">
              <StoryComponent
                url={this.state.story[this.state.newsIndex].url}
                img={this.state.story[this.state.newsIndex].img}
                by={this.state.story[this.state.newsIndex].by}
                date={this.state.story[this.state.newsIndex].date}
                title={this.state.story[this.state.newsIndex].title}
                content={this.state.story[this.state.newsIndex].content}
              />
            </div>
            <div
              className={
                this.state.newsMapIndex + 3 === this.state.story.length
                  ? "neuinx-btn-round news-right-btn-map hidden"
                  : "neuinx-btn-round news-right-btn-map"
              }
              onClick={() => {
                if (this.state.newsMapIndex < this.state.story.length - 1) {
                  this.setState({ newsMapIndex: this.state.newsMapIndex + 1 });
                }
              }}
            >
              <img src={process.env.PUBLIC_URL + "/img/icon/arrow_down.svg"} />
            </div>
            <div
              className={
                this.state.newsIndex === this.state.story.length - 1
                  ? "neuinx-btn-round news-right-btn-mono hidden"
                  : "neuinx-btn-round news-right-btn-mono"
              }
              onClick={() => {
                if (this.state.newsIndex < this.state.story.length - 1) {
                  this.setState({ newsIndex: this.state.newsIndex + 1 });
                }
              }}
            >
              <img src={process.env.PUBLIC_URL + "/img/icon/arrow_down.svg"} />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const Redux = connect((store) => ({}))(Component);

export const NewsComponent = Redux;
