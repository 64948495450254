import React from "react";
import { connect } from "react-redux";
import "../component.css";

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div className="play-cover-component bg-color-F7F7F7">
          <div className="play-cover-flex">
            <div className="play-cover-content">
              <div className="product-play-title text-color-232C48">
                NeuinX Play
              </div>
              <div className="product-play-tags-container">
                <div className="neuinx-tag bg-color-2D6A62 text-color-F2F5E5">
                  Fans
                </div>
                <div className="neuinx-tag bg-color-2D6A62 text-color-F2F5E5">
                  Players
                </div>
                <div className="neuinx-tag bg-color-2D6A62 text-color-F2F5E5">
                  Family
                </div>
              </div>
              <p className="product-play-text text-color-77808C">
                <em>
                  Cherish the Moment &
                  <span className="text-color-DE348C"> Share </span>
                  the Journey
                </em>
              </p>
              <a
                className="neuinx-btn bg-color-232C48"
                href="https://play.neuinx.com/"
                target="_blank"
              >
                Try It Now
              </a>
            </div>
            <div className="play-cover-img-container">
              <img
                className="play-cover-iphone"
                src={process.env.PUBLIC_URL + "/img/background/iPhone.png"}
              />
            </div>
          </div>
          <div className="play-cover-flex">
            <div className="play-cover-illustrate">
              <img
                src={
                  process.env.PUBLIC_URL + "/img/background/Autobox Score.gif"
                }
              />
              <p className="play-cover-describe text-color-232C48">
                <span className="text-color-DE348C">Identify </span>
                Your Moments.
              </p>
            </div>
            <div className="play-cover-illustrate">
              <img src={process.env.PUBLIC_URL + "/img/background/T1 xr.gif"} />
              <p className="play-cover-describe text-color-232C48">
                <span className="text-color-DE348C">Enhance </span>
                Their Beauty.
              </p>
            </div>
            <div className="play-cover-illustrate">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/img/background/3D Pose Analysis.gif"
                }
              />
              <p className="play-cover-describe text-color-232C48">
                <span className="text-color-DE348C">Analyze </span>
                to Perfection.
              </p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const Redux = connect((store) => ({}))(Component);

export const PlayCoverComponent = Redux;
