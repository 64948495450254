import React from "react";
import { connect } from "react-redux";
import ReactPlayer from "react-player/lazy";
import "../component.css";

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "https://www.youtube.com/watch?v=1GrM_Vx1h3Q",
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className="video-container-component bg-color-F7F7F7">
          <div className="video-container-title text-color-232C48">
            Promotion Video
          </div>
          <div className="video-container-content">
            <ReactPlayer
              url={this.state.url}
              width={"100%"}
              height={"100%"}
              type="video/mp4"
              playing
              loop
              muted
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const Redux = connect((store) => ({}))(Component);

export const PromotionVideoComponent = Redux;
